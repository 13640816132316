const PluggedInModules = {}
try {
     PluggedInModules["configs"] = {
       path: require("@devModules/abraa_modules/configs"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
}
try {
     PluggedInModules["suppliers"] = {
       path: require("@devModules/abraa_modules/suppliers"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
   }
try {
  PluggedInModules["user"] = {
    path: require("@devModules/user"),
    enabled: true
  } // special user Module ()
} catch (e) {
  console.error("no Ewallet User Module", e)
}
try {
     PluggedInModules["home-items"] = {
       path: require("@devModules/abraa_modules/home-items"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
}
try {
     PluggedInModules["static-pages"] = {
       path: require("@devModules/abraa_modules/static-pages"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
}
try {
     PluggedInModules["buyers"] = {
       path: require("@devModules/abraa_modules/buyers"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
}
try {
     PluggedInModules["shippers"] = {
       path: require("@devModules/abraa_modules/shippers"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
}
try {
     PluggedInModules["stores"] = {
       path: require("@devModules/abraa_modules/stores"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
}
try {
     PluggedInModules["products"] = {
       path: require("@devModules/abraa_modules/products"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
}
try {
     PluggedInModules["orders"] = {
       path: require("@devModules/abraa_modules/orders"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
}
try {
     PluggedInModules["categories"] = {
       path: require("@devModules/abraa_modules/categories"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
}
try {
     PluggedInModules["members"] = {
       path: require("@devModules/abraa_modules/members"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
   }
   try {
     PluggedInModules["advertisements"] = {
       path: require("@devModules/abraa_modules/advertisements"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
   }
   try {
     PluggedInModules["abraa-messages"] = {
       path: require("@devModules/abraa_modules/abraa-messages"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
   }
   try {
     PluggedInModules["memberships"] = {
       path: require("@devModules/abraa_modules/memberships"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
   }
   try {
     PluggedInModules["request-for-qoutation"] = {
       path: require("@devModules/abraa_modules/request-for-qoutation"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
   }
   try {
     PluggedInModules["general"] = {
       path: require("@devModules/abraa_modules/general"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
   }
   try {
     PluggedInModules["reports"] = {
       path: require("@devModules/abraa_modules/reports"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
   }

   try {
     PluggedInModules["notifications"] = {
       path: require("@devModules/abraa_modules/notifications"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
   }

   try {
     PluggedInModules["guidelines"] = {
       path: require("@devModules/abraa_modules/guidelines"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
   }

   try {
     PluggedInModules["clear-cache"] = {
       path: require("@devModules/abraa_modules/clear-cache"),
       enabled: true
     } // special user Module ()
   } catch (e) {
     console.error("no Ewallet User Module", e)
   }
export default PluggedInModules
