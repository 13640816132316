import React from "react"
import { Circle, User, Calendar, MessageCircle, List } from "react-feather"

export const Navigation = [
  {
    id: "requestforqoutation",
    title: "Request For Quotation",
    icon: <List color={"#fff"} size={20} />,
    children: [
      {
        id: "abandonedbuyingquotation",
        title: "Abandoned Buying Quotation",
        //     icon: <Circle size={ 12 } />,
        navLink: "/request-for-qoutation/abandoned-buying-quotation",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "buyingrequestinvoices",
        title: "Buying Request Invoices",
        //     icon: <Circle size={ 12 } />,
        navLink: "/request-for-qoutation/buying-request-invoices",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "closedbuyingquotation",
        title: "Closed Buying Quotation",
        //     icon: <Circle size={ 12 } />,
        navLink: "/request-for-qoutation/closed-buying-quotation",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "globalbuyingquotation",
        title: "Global Buying Quotation",
        //     icon: <Circle size={ 12 } />,
        navLink: "/request-for-qoutation/global-buying-quotation",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "pendingbuyingquotation",
        title: "Pending Buying Quotation",
        //     icon: <Circle size={ 12 } />,
        navLink: "/request-for-qoutation/pending-buying-quotation",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "productbuyingrequests",
        title: "Product Buying Requests",
        //     icon: <Circle size={ 12 } />,
        navLink: "/request-for-qoutation/product-buying-requests",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      }
    ]
  }
]
