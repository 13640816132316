import { lazy } from "react"

export const Routes = [
  {
    path: "/guidelines/guidelines-list",
    component: lazy(() => import("../views/guidelines-list")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  }
]
