import { lazy } from "react"

export const Routes = [
  {
    path: "/configs/countries-states",
    component: lazy(() => import("../views/countries-states")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     },
     {
       path: "/configs/payment-options",
       component: lazy(() => import("../views/payment-options")),
       meta: {
        action: "call",
        resource: "NoPermissionCode"
      }
        },
        {
          path: "/configs/website-config",
          component: lazy(() => import("../views/website-config")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
           },
           {
             path: "/configs/website-countries",
             component: lazy(() => import("../views/website-countries")),
             meta: {
              action: "call",
              resource: "NoPermissionCode"
            }
              },
              {
                path: "/configs/website-units",
                component: lazy(() => import("../views/website-units")),
                meta: {
                 action: "call",
                 resource: "NoPermissionCode"
               }
                 }
]
