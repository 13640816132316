import { lazy } from "react"

export const Routes = [
  {
    path: "/login",
    component: lazy(() => import("../views/auth/login")),
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  },
  {
    path: "/loginotp",
    component: lazy(() => import("../views/auth/loginOtp")),
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  },
  {
    path: "/signin",
    component: lazy(() => import("../views/auth/login")),
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  },
  {
    path: "/signup",
    component: lazy(() => import("../views/auth/signup")),
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../views/auth/forget-password")),
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  },
  {
    path: "/forgot-pin",
    component: lazy(() => import("../views/auth/forget-pin")),
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  },
  {
    path: "/set-pin",
    component: lazy(() => import("../views/auth/set-pin")),
    layout: "BlankLayout",
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/reset-password/:email",
    component: lazy(() => import("../views/auth/old/ResetPassword")),
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  },
  {
    path: "/dashboard",
    component: lazy(() => import("@src/views/dashboard")),
    meta: {
      action: "call",
      resource: "USER_VIEW_DASHBOARD"
    }
  },
  {
    path: "/account_type",
    component: lazy(() => import("../views/auth/accountType")),
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  },
  {
    path: "/user/list",
    component: lazy(() => import("../views/user-list")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/user/role/list",
    component: lazy(() => import("../views/role-list")),
    meta: {
      action: "call",
      resource: "USR_VIEW_ROLE_LIST"
    }
  },
  {
    path: "/user/kyc/levels",
    component: lazy(() => import("../views/kyc-sittings")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/user/kyc/list",
    component: lazy(() => import("../views/kyc-level-list")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/user/extra-fields/list",
    component: lazy(() => import("../views/extra-fields")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/user/extra-fields-types/list",
    component: lazy(() => import("../views/extra-fields-types")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/user/setting/securityConfig",
    component: lazy(() => import("../views/security-settings/SecurityConfigTabContent")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/user/setting/AutoLogin",
    component: lazy(() => import("../views/security-settings/AutoLoginTabContent")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/user/calender",
    component: lazy(() => import("../views/Calender/index")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/user/setting/kyc/:id",
    component: lazy(() => import("../views/kyc-roles/index")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  }
]
