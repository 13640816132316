import React from "react"
import { Circle, User, Calendar, MessageCircle, MessageSquare } from "react-feather"

export const Navigation = [
  {
    id: "notifications",
    title: "Notifications",
    icon: <MessageSquare color={"#fff"} size={20} />,
    children: [
      {
        id: "notificat",
        title: "Notifications",
        //     icon: <Circle size={ 12 } />,
        navLink: "/notifications",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      }
    ]
  }
]
