import React from "react"
import { Circle, User, Calendar, MessageCircle } from "react-feather"

export const Navigation = [
  {
    id: "orders",
    title: "Orders",
    icon: <MessageCircle color={"#fff"} size={20} />,
    children: [
      {
        id: "order",
        title: "orders",
        //     icon: <Circle size={ 12 } />,
        navLink: "/orders/orders",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      }
    ]
  }
]
