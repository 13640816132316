import React from "react"
import { Circle, User, Calendar, MessageCircle, Home } from "react-feather"

export const Navigation = [
  {
    id: "home-items",
    title: "Home Items",
    icon: <Home color={"#fff"} size={20} />,
    children: [
      {
        id: "homepagebuyers",
        title: "Home Page Buyers",
        //     icon: <Circle size={ 12 } />,
        navLink: "/home-items/home-page-buyers",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
         },
         {
          id: "services",
          title: "Services",
          //     icon: <Circle size={ 12 } />,
          navLink: "/home-items/services",
          action: "call",
          resource: "USR_VIEW_ROLE_LIST"
        }
    ]
  }
]
