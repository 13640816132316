import { lazy } from "react"

export const Routes = [
  {
    path: "/home-items/home-page-buyers",
    component: lazy(() => import("../views/home-page-buyers")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/home-items/services",
    component: lazy(() => import("../views/services")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  }
]
