import { lazy } from "react"

export const Routes = [
  {
    path: "/memberships/plans",
    component: lazy(() => import("../views/plans")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     },
     {
          path: "/memberships/call-requests",
          component: lazy(() => import("../views/call-requests")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/memberships/subscriptions",
          component: lazy(() => import("../views/subscriptions")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/memberships/transactions",
          component: lazy(() => import("../views/transactions")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
           }
]
