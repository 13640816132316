import { lazy } from "react"

export const Routes = [
  {
    path: "/advertisement/ads",
    component: lazy(() => import("../views/ads")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     },
     {
          path: "/advertisement/Ads-area",
          component: lazy(() => import("../views/Ads-area")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/advertisement/home-banners",
          component: lazy(() => import("../views/home-banners")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/advertisement/home-slider",
          component: lazy(() => import("../views/home-slider")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/advertisement/RFQ-slider",
          component: lazy(() => import("../views/RFQ-slider")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
           }
]
