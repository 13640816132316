import React from "react"
import { Circle, User, Calendar, MessageCircle } from "react-feather"

export const Navigation = [
  {
    id: "messageModule",
    title: "Abraa Messages",
    icon: <MessageCircle color={"#fff"} size={20} />,
    children: [
      {
        id: "abraaList",
        title: "All Messages",
        //     icon: <Circle size={ 12 } />,
        navLink: "/abraa-messages/all-messages",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "sendmessage",
        title: "Send Message",
        //     icon: <Circle size={ 12 } />,
        navLink: "/abraa-messages/send-message",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      }
      //  }
      //  {
      //    id: 'userRole',
      //    title: 'user.nav.userRole',
      //    icon: <Circle size={12} />,
      //    navLink: '/user/role/list',
      //    action: 'call',
      //    resource: 'USR_VIEW_ROLE_LIST'
      //  },
      //  {
      //    id: 'KycSetting',
      //    title: 'user.nav.KycSettings',
      //    icon: <Circle size={12} />,
      //    navLink: '/user/kyc/levels',
      //    action: 'call',
      //    resource: 'NoPermissionCode'
      //  },
      //  {
      //    id: 'user.calender.calender',
      //    title: 'user.calender.calender',
      //    icon: <Calendar size={14} />,
      //    navLink: '/user/calender',
      //    action: 'call',
      //    resource: 'NoPermissionCode'
      //  },
      //  {
      //    id: 'securityConfig',
      //    title: 'user.nav.securityConfig',
      //    icon: <Circle size={12} />,
      //    navLink: '/user/setting/securityConfig',
      //    action: 'call',
      //    resource: 'USR_VIEW_SECURITY_CONFIG'
      //  },
      //  {
      //    id: 'AutoLogin',
      //    title: 'user.nav.AutoLogin',
      //    icon: <Circle size={12} />,
      //    navLink: '/user/setting/AutoLogin',
      //    action: 'call',
      //    resource: 'USR_VIEW_AUTOLOGIN_CONFIG'
      //  },
      //  {
      //    id: 'KycList',
      //    title: 'user.nav.KycList',
      //    icon: <Circle size={12} />,
      //    navLink: '/user/kyc/list',
      //    action: 'call',
      //    resource: 'NoPermissionCode'
      //  },
      //  {
      //    id: 'AttributeList',
      //    title: 'user.nav.AttributeList',
      //    icon: <Circle size={12} />,
      //    navLink: '/user/extra-fields/list',
      //    action: 'call',
      //    resource: 'NoPermissionCode'
      //  },
      //  {
      //    id: 'AttributeTypeList',
      //    title: 'user.nav.AttributeTypeList',
      //    icon: <Circle size={12} />,
      //    navLink: '/user/extra-fields-types/list',
      //    action: 'call',
      //    resource: 'NoPermissionCode'
      //  }
      // {
      //   id: 'userPermissions',
      //   title: 'user.nav.userPermissions',
      //   icon: <Circle size={12} />,
      //   navLink: '/user/permissions',
      //   action: 'call',
      //   resource: 'NoPermissionCode'
      // },
      // {
      //   id: 'userSettings',
      //   title: 'user.nav.userSettings',
      //   icon: <Circle size={12} />,
      //   navLink: '/user/settings',
      //   action: 'call',
      //   resource: 'NoPermissionCode'
      // }
    ]
  }
]
