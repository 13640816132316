import React from "react"
import { Circle, User, Calendar, MessageCircle, Users } from "react-feather"

export const Navigation = [
  {
    id: "members",
    title: "Members",
    icon: <Users color={"#fff"} size={20} />,
    children: [
      {
        id: "usersactivities",
        title: "Users Activities",
        //     icon: <Circle size={ 12 } />,
        navLink: "/members/users-activities",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      }
    ]
  }
]
