import { lazy } from "react"

export const Routes = [
  {
    path: "/products/all-items",
    component: lazy(() => import("../views/all-items")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     },
     {
          path: "/products/import-items",
          component: lazy(() => import("../views/import-items")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/products/items-files",
          component: lazy(() => import("../views/items-files")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
           }
]
