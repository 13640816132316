import React from "react"
import { Circle, User, Calendar, MessageCircle, Home } from "react-feather"

export const Navigation = [
  {
    id: "stores",
    title: "Stores",
    icon: <Home color={"#fff"} size={20} />,
    children: [
      {
        id: "abandonedstores",
        title: "Abandoned Stores",
        //     icon: <Circle size={ 12 } />,
        navLink: "/stores/abandoned-stores",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
         },
         {
          id: "activedstores",
          title: "Active Stores",
          //     icon: <Circle size={ 12 } />,
          navLink: "/stores/active-stores",
          action: "call",
          resource: "USR_VIEW_ROLE_LIST"
         },
         {
          id: "allstores",
          title: "All Stores",
          //     icon: <Circle size={ 12 } />,
          navLink: "/stores/all-stores",
          action: "call",
          resource: "USR_VIEW_ROLE_LIST"
         },
         {
          id: "bulkstores",
          title: "Bulk Stores",
          //     icon: <Circle size={ 12 } />,
          navLink: "/stores/bulk-stores",
          action: "call",
          resource: "USR_VIEW_ROLE_LIST"
         },
         {
          id: "waitingstores",
          title: "Waiting Stores",
          //     icon: <Circle size={ 12 } />,
          navLink: "/stores/waiting-stores",
          action: "call",
          resource: "USR_VIEW_ROLE_LIST"
        }
    ]
  }
]
