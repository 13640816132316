import { lazy } from "react"

export const Routes = [
  {
    path: "/reports",
    component: lazy(() => import("../views")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     }
]
