import React from "react"
import { Circle, User, Calendar, MessageCircle, Settings } from "react-feather"

export const Navigation = [
  {
    id: "configs",
    title: "Configs",
    icon: <Settings color={"#fff"} size={20} />,
    children: [
      {
        id: "countriesstates",
        title: "Countries States",
        //     icon: <Circle size={ 12 } />,
        navLink: "/configs/countries-states",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "paymentoptions",
        title: "Payment Options",
        //     icon: <Circle size={ 12 } />,
        navLink: "/configs/payment-options",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "websiteconfig",
        title: "Website Config",
        //     icon: <Circle size={ 12 } />,
        navLink: "/configs/website-config",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "websitecountries",
        title: "Website Countries",
        //     icon: <Circle size={ 12 } />,
        navLink: "/configs/website-countries",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "websiteunits",
        title: "Website Units",
        //     icon: <Circle size={ 12 } />,
        navLink: "/configs/website-units",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      }
    ]
  }
]
