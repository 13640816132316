import { _setAPIToken } from '../../utility/Utils'
import { API } from "@fwsrc/utility/API"
import { store } from "@fwsrc/redux/storeConfig/store"
import { toast } from 'react-toastify'
//************************************//
export const _login = ({ mobile_phone, password }, callback, callbackErr) => {
  const token = JSON.parse(localStorage.getItem('USER_TOKEN'))?.token
  API.post('/api/v1/auth/login', { mobile_phone, password })
    .then(function ({ data }) {
      _setAPIToken(data.access_token)
      localStorage.setItem("USER_TOKEN", JSON.stringify({ token: data.access_token, userData: data }))
      callback(data)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}
//************************************//
export const _loginWithOtp = (id_token, password, callback, callbackErr) => {
  API.post('/api/v1/auth/login', { id_token, password })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}
//************************************//
export const _loginTwoFactor = ({ email, code }, callback, callbackErr) => {
  API.post('/login-2fa', { email, code })
    .then(function (res) {
      callback(res)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _register = ({ id_token, password, roles, username }, callback, callbackErr) => {
  return dispatch => {
    API.post(`/api/v1/auth/signup`, { id_token, password, roles, username })
      .then(function ({ data }) {
        callback(data)
      })
      .catch(function () {
        callbackErr()
      })
  }
}
//************************************//
export const _setPin = ({ mobile_phone, password, pin }, callback, callbackErr) => {
  API.post(`/api/v1/users/set_pin`, { mobile_phone, password, pin })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function () {
      callbackErr()
    })
}
//************************************//
export const _checkPin = (pin, callback, callbackErr) => {
  API.post(`/api/v1/users/check_pin`, { pin })
    .then(function (data) {
      callback(data)
    })
    .catch(function (e) {
      callbackErr()
    })
}
//************************************//
export const _forgetPassword = (data, callback, callbackErr) => {
  API.post('/api/v1/auth/resetPasswordUsingOTP', data)
    .then(function (res) {
      callback(res.data)
    })
    .catch(function (res) {
      callbackErr(res?.data?.data)
    })
}
//************************************//
export const _resetPassword = ({ email, currentPassword, newPassword }, callback, callbackErr) => {
  API.post('/reset-password', { email, current_password: currentPassword, new_password: newPassword })
    .then(function (res) {
      callback(res)
    })
    .catch(function ({ data }) {
      callbackErr(data.data)
    })
}
//************************************//
export const _changePassword = (data, callback, callbackErr) => {
  API.post('/user/account/change-password', { ...data })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data.data)
    })
}
//************************************//
export const _checkNumberExist = (id_token, callback, callbackErr) => {
  API.post('/api/v1/users/check_existing_phone', { id_token })
    .then(function (res) {
      callback(res)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _logout = () => {
  return dispatch => {
    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem('USER_TOKEN')
    sessionStorage.removeItem('USER_TOKEN')
    _setAPIToken('')
    dispatch({ type: 'USER_LOGOUT' })
  }
}

//************************************//
export const _autoLogin = (ability, callback, callbackError) => {
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('USER_TOKEN'))?.token
    console.log(token, "profile token loaded")
    if (token) {
      _setAPIToken(token)
      API.get('/api/v1/users/profile')
        .then(function (res) {
          if (res.isSuccessful) {
            const { data } = res
            localStorage.setItem("USER_TOKEN", JSON.stringify({ token, userData: data }))
            // dispatch({type: "USER_LOGIN", userData: data, token})
            // ability.update([
            //   {
            //     subject: "all",
            //     action: "manage"
            //   }
            // ])
          }
          callback()
        })
        .catch(function (res) {
          callbackError()
        })
    }
  }
}
//************************************//
export const _getMyProfile = (dispatch) => {
  API.get('/user/account/my-profile')
    .then(function ({ data }) {
      const userToken = JSON.parse(sessionStorage.getItem('USER_TOKEN'))
      userToken.user = { ...userToken.user, ...data.basic_info }
      sessionStorage.setItem("USER_TOKEN", JSON.stringify(userToken))
      dispatch({ type: 'USER_MY_PROFILE', data })
    })
    .catch(function (res) { })
}
//************************************//
export const _changeSecurityConfig = (data, callback, callbackErr) => {
  API.post('/api/v1/micro_configs/update', data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}
//************************************//
export const _getSecurityConfig = (callback, callbackErr) => {
  API.get('/api/v1/micro_configs/show')
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}
//************************************//
export const _getAutoLoginInfo = (callback, callbackErr) => {
  API.get('/api/v1/micro_configs/show', { baseURL: "https://ramaaz.dev/market-back-end/logs/public" })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}
//************************************//
export const _changeAutoLoginInfo = (data, callback, callbackErr) => {
  API.post('/api/v1/micro_configs/update', data, { baseURL: "https://ramaaz.dev/market-back-end/logs/public" })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}
//************************************//
export const _changeGeneralInfo = (data, callback, callbackErr) => {
  return dispatch => {
    API.post('/user/account/change-general-info', { ...data })
      .then(function ({ data }) {
        _getMyProfile(dispatch)
      })
      .catch(function ({ data }) {
        callbackErr(data.data)
      })
  }

}
//************************************//
export const _getRoleList = ({ limit, page, sort, filter }, callback) => {
  API.get('/api/v1/roles', {
    params: {
      limit,
      page,
      sort,
      filter,
      datatables: 1
    }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _deleteRole = (id, callback) => {
  API.post(`/api/v1/roles/destroy`, { id })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
    })
}
//************************************//
export const _getUserList = ({ limit, page, sort, filter }, callback) => {
  API.get('/api/v1/users', {
    params: {
      limit,
      page,
      sort,
      filter,
      datatables: true,
      users_filter: []
    }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _getBlockingIPsList = ({ limit, page, sort, filter }, callback) => {
  API.get('/api/v1/blocked_ips', {
    params: {
      limit,
      page,
      sort,
      filter,
      datatables: true
    }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _getBlockingCountryList = ({ limit, page, sort, filter }, callback) => {
  API.get('/api/v1/blocked_countries', {
    params: {
      limit,
      page,
      sort,
      filter,
      datatables: true
    }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
export const _getBlockingSubnetList = ({ limit, page, sort, filter }, callback) => {
  API.get('/api/v1/blocked_subnets', {
    params: {
      limit,
      page,
      sort,
      filter,
      datatables: true
    }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _deleteIP = (id, callback) => {
  API.post(`/api/v1/blocked_ips/destroy`, { id })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
    })
}
//************************************//
export const _deleteCountry = (id, callback) => {
  API.post(`/api/v1/blocked_countries/destroy`, { id })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
    })
}
export const _deleteSubnet = (id, callback) => {
  API.post(`/api/v1/blocked_subnets/destroy`, { id })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
    })
}
//************************************//
export const _deleteUser = (id, callback) => {
  API.post(`/api/v1/users/delete`, { id })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
    })
}
//************************************//
export const _invite = ({ firstName, lastName, email }, callback, callbackErr) => {
  API.post('/user/invite', { first_name: firstName, last_name: lastName, email })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data.data)
    })
}
//************************************//
export const _resendInvite = (id) => {
  API.post(`/user/${id}/resend-invite`)
    .then(function ({ data }) {
    })
    .catch(function ({ data }) {
    })
}
//************************************//
export const _addIP = (data, callback, callbackErr) => {
  API.post(`/api/v1/blocked_ips/create`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}
//************************************//
export const _addUser = (data, callback, callbackErr) => {
  API.post(`/api/v1/users/create`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}
//************************************//
export const _editUserInfo = ({ id, username, roles }, callback, callbackErr) => {
  API.post(`/api/v1/users/update`, { id, username, roles })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}
//************************************//
export const _addRole = (data, callback, callbackErr) => {
  API.post('/api/v1/roles/create', data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}
//************************************//
export const _editRole = (data, callback, callbackErr) => {
  API.post(`/api/v1/roles/update`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}
//************************************//
export const _getPermissions = ({ }, callback) => {
  API.get('/user/role/get-permissions', {
    params: {}
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _updatePermissions = (permissions, callback) => {
  API.post('/user/role/update-permissions', { permissions })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function () { })
}
//************************************//
export const _updateUserRole = ({ id, roles }, callback) => {
  API.post(`/user/${id}/update-roles`, { roles })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
//************************************//
export const _searchRole2 = async (q = '') => {
  const res = await API.get('/api/v1/roles')
  const result = _.map(res.data, (v, k) => {
    return { value: v.id, label: v.role_translations.name }
  })
  store.dispatch({
    type: "store_types",
    data: result
  })
  return result
}
//************************************//
export const _searchRole = async (q = '') => {
  const res = await API.get('/api/v1/roles')
  const result = _.filter(res.data, (v, k) => {
    return (v.is_signup_allowed === "1")
  }).map((b) => {
    return { value: b.id, label: b.role_translations.name }
  })
  const result2 = _.map(res.data, (v, k) => {
    return { value: v.id, label: v.role_translations.name, is_signup_allowed: v.is_signup_allowed }
  })
  store.dispatch({
    type: "store_types",
    data: result
  })
  store.dispatch({
    type: "store_roles_for_menu",
    data: result2
  })
  return result
}
//************************************//
export const _searchRoleFilter = async (q = '') => {
  const res = await API.get('/api/v1/roles')
  const result = _.map(res.data, (v, k) => {
    return { value: v.id, label: v.role_translations.name }
  })
  return result
}
//************************************//
export const _updateUserStatus = (data, callback) => {
  API.post(`/user/${data.userId}/change-status`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
//************************************//
export const _getKycList = ({ limit, page, sort, filter }, callback) => {
  const role = store.getState().user.role_id
  API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/kyc_levels/kyc_of_role/${role}`, {
    params: {
      limit,
      page,
      sort,
      filter,
      datatables: true
    }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _deleteKyc = (id, callback) => {
  API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/kyc_levels/destroy/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
    })
}
//************************************//
export const _editKyc = (data, callback, callbackErr) => {
  API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/kyc_levels/update`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _addKyc = (data, callback, callbackErr) => {
  API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/kyc_levels/create`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _getLanguages = async () => {
  const res = await API.get(`${process.env.REACT_APP_CATEGORIES_CONTENT_BACKEND_URL}/api/v1/languages/index`)
  const result = _.map(res.data.languages, (v, k) => {
    return { value: v.language_code, label: v.name }
  })
  store.dispatch({
    type: "languages_get",
    data: res.data.languages
  })
  return result
}
//************************************//
export const _getAttributesList = ({ limit, page, sort, filter }, callback) => {
  API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attributes`, {
    params: {
      limit,
      page,
      sort,
      filter,
      datatables: true
    }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _deleteAttribute = (id, callback) => {
  API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attributes/destroy`, id)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
    })
}
//************************************//
export const _editAttribute = (data, callback, callbackErr) => {
  API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attributes/update`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _addAttribute = (data, callback, callbackErr) => {
  API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attributes/create`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _getAttributeTypesList = ({ limit, page, sort, filter }, callback) => {
  API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attribute_types`, {
    params: {
      limit,
      page,
      sort,
      filter,
      datatables: true
    }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _deleteAttributeType = (id, callback) => {
  API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attribute_types/destroy`, id)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) {
    })
}
//************************************//
export const _editAttributeType = (data, callback, callbackErr) => {
  API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attribute_types/update`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//************************************//
export const _addAttributeType = (data, callback, callbackErr) => {
  API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attribute_types/create`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//***************************************//
export const _searchTypes = async () => {
  const res = await API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attribute_types`)
  const result = _.map(res.data, (v, k) => {
    return { label: v.attribute_type_translations[0].name, value: v.id }
  })
  store.dispatch({
    type: "get_attribute_types",
    data: result
  })
  return result
}
//***************************************//
export const _searchCategories = async () => {
  const res = await API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attribute_categories/index`)
  const result = _.map(res.data, (v, k) => {
    return { label: v.attribute_category_translation[0].name, value: v.id }
  })
  return result
}
//***************************************//
export const _setKycRelation = (data, callback, callbackErr) => {
  API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/kyc_levels/check`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (res) { })
}
//***************************************//
export const _setRole = (id) => {
  store.dispatch({
    type: "roles_get",
    data: id
  })
}
export const _getKycRelationsList = ({ limit, page, sort, filter }, callback) => {
  const role = store.getState().user.role_id
  if (role) {
    API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/kyc_levels/kyc_levels_setting/${role}`, {
      params: {
        limit,
        page,
        sort,
        filter,
        datatables: true
      }
    })
      .then(function ({ data }) {
        callback(data)
      })
      .catch(function (res) { })
  }
}
//***********************************//
export const _signInWithPhoneNumber = (inputValue, callback) => {
  API.post(`${process.env.REACT_APP_SMS_BACKEND_URL}/api/v1/otp/verification_code`, { phoneNumber: inputValue })
    .then(function ({ data }) {
      callback(data.sessionInfo)
    })
}
//************************************//
export const _checkValidNumber = async (id_token, callback, failcallback) => {
  await API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/users/check_existing_phone`, {
    id_token
  }).then(function (res) {
    if (!res.data) {
      callback(id_token)
    } else {
      failcallback()
    }
  })
    .catch(function (res) {
      failcallback()
    })
}
//************************************//
export const _verifyPhoneNumber = async (verificationId, pin, callback) => {
  await API.post(`${process.env.REACT_APP_SMS_BACKEND_URL}/api/v1/otp/verifyPhoneNumber`, { code: pin, sessionInfo: verificationId })
    .then(function (data) {
      callback(data.data.idToken)
    })
}
//************************************//
export const _getAttributesCategories = async () => {
  await API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attribute_categories/index`)
    .then(function (res) {
      store.dispatch({
        type: "getAttributesCategories",
        data: res.data
      })
    })
}
//************************************//
export const _getKycExtraFields = async (id) => {
  await API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attributes/kyc_extra_fields_of_role/${id}`)
    .then(function (res) {
      store.dispatch({
        type: "kycExtraFields",
        data: res.data
      })
    })
}
//************************************//
export const _getProfileExtraFields = async (id) => {
  await API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attributes/profile_extra_fields_of_role/${id}`)
    .then(function (res) {
      store.dispatch({
        type: "profileExtraFields",
        data: res.data
      })
    })
}
//************************************//
export const _searchProfile = async () => {
  const res = await API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attributes/extra_fields_of_category/1`)
  const result = _.map(res.data, (v, k) => {
    return { label: v.attribute_translations[0].name, value: v.id }
  })
  return result
}
//***************************************//
export const _searchKyc = async () => {
  const res = await API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attributes/extra_fields_of_category/2`)
  const result = _.map(res.data, (v, k) => {
    return { label: v.attribute_translations[0].name, value: v.id }
  })
  return result
}
//***************************************//
export const _getRoleAttributes = async (id) => {
  await API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attributes/profile_extra_fields_of_role/${id}`)
    .then(function (res) {
      store.dispatch({
        type: "profileAttributes",
        data: res.data
      })
    })
  await API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/kyc_levels/kyc_level_with_attributes/${id}`)
    .then(function (res) {
      store.dispatch({
        type: "kycAttributes",
        data: res.data
      })
    })
}
//****************************************//
export const _getSvg = async (link) => {
  await API.get(link).
  then(function (res) {
    return (res)
  })
}
//****************************************//
export const _saveValues = async (data) => {
  await API.post('/api/v1/attribute_values/save', data).
  then(function (res) {
    _getRoleAttributes(data.attribute_values[0].role_id)
    toast.success(res.data)
  })
}
//****************************************//
export const _getRole = async (data) => {
  await API.post('/api/v1/roles/add_role_to_user', data).
  then(function (res) {
    toast.success("now you have this role :)")
  })
}
//****************************************//
export const _ensureMyAccount = async (data) => {
  await API.post('/api/v1/users/insured_my_account', data).
  then(function (res) {
    toast.success("now you ensured your account :)")
  })
}
export const wait = () => {
  return {
    type: "WAIT"
  }
}
export const done = () => {
  return {
    type: "DONE"
  }
}
let eventGuid = 0
const todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
export function createEventId() {
  return String(eventGuid++)
}
export const _getActions = () => {
  wait()
  API.get("https://ramaaz.dev/e_wallet/actions_service/public/api/v1/actions/index").then(function ({ data }) {
    console.log(data)
    store.dispatch({
      type: "GET_ACTION",
      payload: data.data
    })
    done()
  })
}
//****************************************//
export const _addActions = (data) => {
  API.post("https://ramaaz.dev/e_wallet/actions_service/public/api/v1/actions/store", { ...data }).then(function ({ data }) {
    store.dispatch({
      type: "ADD_ACTION",
      payload: data
    })
  })
}
//****************************************//
export const _myActions = (data) => {
  API.post("https://ramaaz.dev/e_wallet/actions_service/public/api/v1/actions/web/my_actions_of_day", { day: data }).then(function ({ data }) {
    store.dispatch({
      type: "MY_ACTION",
      payload: data
    })
  })
}
//****************************************//
export const _updateAction = (data) => {
  API.post("https://ramaaz.dev/e_wallet/actions_service/public/api/v1/actions/update", { ...data }).then(function ({ data }) {
  })
}
//****************************************//
export const _delActions = (id) => {
  API.post(`https://ramaaz.dev/e_wallet/actions_service/public/api/v1/actions/delete`, { id }).then(function ({ data }) {
    store.dispatch({
      type: "DEL_ACTION",
      payload: id
    })
  })
}
//****************************************//
export const getUserList = () => {
  API.get('/api/v1/users')
    .then(function ({ data }) {
      store.dispatch({
        type: "USER_RED",
        payload: data
      })
    })
    .catch(function (res) { })
}
////////////////////////////////////////////////////////
export const _getUserAttributes = async (id) => {
  await API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/users/kyc_levels_of_user/${id}`)
    .then(function (res) {
      store.dispatch({
        type: "getUserAttributes",
        data: res.data
      })
    })
}
////////////////////////////////////////////////////////
export const _approveUserAttributes = async (id, callback) => {
  await API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/attribute_values/approve`, { id })
    .then(function (res) {
      callback()
    })
}
//****************************************//
export const _getAllowedToSignUpRoles = async (callback) => {
  await API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v2/roles/allowed_to_signup`)
    .then(function (res) {
      callback(res.data)
    })
}
//****************************************//
export const _changeCurrentRole = async (role_name, callback) => {
  await API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/users/change_role`, {
    id: store.getState().user.userData.id,
    role_name
  })
    .then(function (res) {
      callback(res)
    })
}
//****************************************//
export const _insureAccount = async (role_id, callback) => {
  await API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/users/insured_my_account`, { role_id })
    .then(function (res) {
      callback(res)
    })
}