// **  Initial State
const initialState = {
    userData: {},
    token: false,
    loading: false,
    languages: [],
    role_id: null,
    attribute_types: [],
    menuRoles: [],
    roles: [],
    categories: [],
    profileAttributes: [],
    kycAttributes: [],
    action_types: [],
    actions: [],
    myactions: [],
    users: [],
    UserAttributes: [],
    loadings: true,
    role_name: "super_admin"
}

export const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "profileAttributes":
            return { ...state, profileAttributes: action.data }
        case "setSignUpRole":
            return { ...state, role_name: action.role }
        case "getUserAttributes":
            return { ...state, UserAttributes: action.data }
        case "kycAttributes":
            return { ...state, kycAttributes: action.data }
        case "store_roles_for_menu":
            return { ...state, menuRoles: action.data }
        case "get_attribute_types":
            return { ...state, attribute_types: action.data }
        case "languages_get":
            return { ...state, languages: action.data }
        case "getAttributesCategories":
            return { ...state, categories: action.data }
        case "roles_get":
            return { ...state, role_id: action.data }
        case "store_types":
            return { ...state, roles: action.data }
        case 'USER_LOGIN':
            return {
                ...state,
                userData: action.userData,
                token: action.token
            }
        case 'USER_LOGOUT':
            const obj = { ...action }
            return { ...state, userData: {}, token: false, ...obj }
        case 'USER_MY_PROFILE':
            const userData = { ...state.userData }
            userData.first_name = action.data.basic_info.first_name
            userData.last_name = action.data.basic_info.last_name
            userData.full_name = action.data.basic_info.full_name
            userData.avatar = action.data.general_info.avatar
            userData.gender = action.data.general_info.gender
            userData.birth_date = action.data.general_info.birth_date
            return {
                ...state,
                userData
            }
        case 'USER_INFO_LOADING_START':
            return {
                ...state, loading: true
            }
        case 'USER_INFO_LOADING_END':
            return {
                ...state, loading: false
            }
        case "GET_ACTION":
            return {
                ...state,
                actions: action.payload
            }
        case "MY_ACTION":
            return {
                ...state,
                myactions: action.payload
            }
        case "ADD_ACTION":
            return {
                ...state,
                actions: [...state.actions, action.payload]
            }
        case "DEL_ACTION":
            return {
                ...state,
                actions: state.actions.filter((act) => act.id !== action.payload)
            }
        case "DONE":
            return {
                ...state,
                loadings: false
            }
        case "WAIT":
            return {
                ...state,
                loadings: true
            }
        case "USER_RED":
            return {
                ...state, users: action.payload
            }
        default:
            return state
    }
}
