import { lazy } from "react"

export const Routes = [
  {
    path: "/abraa-messages/all-messages",
    component: lazy(() => import("../views/all-messages")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/abraa-messages/send-message",
    component: lazy(() => import("../views/send-message")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  }
]
