import { lazy } from "react"

export const Routes = [
  {
    path: "/clear-cache",
    component: lazy(() => import("../views/index.js")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     }
]
