import React from "react"
import { Circle, User, Calendar, MessageCircle } from "react-feather"

export const Navigation = [
  {
    id: "memberships",
    title: "Memberships",
    icon: <User color={"#fff"} size={20} />,
    children: [
      {
        id: "callrequests",
        title: "Call Requests",
        //     icon: <Circle size={ 12 } />,
        navLink: "/memberships/call-requests",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "plans",
        title: "Plans",
        //     icon: <Circle size={ 12 } />,
        navLink: "/memberships/plans",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "subscriptions",
        title: "Subscriptions",
        //     icon: <Circle size={ 12 } />,
        navLink: "/memberships/subscriptions",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "transactions",
        title: "Transactions",
        //     icon: <Circle size={ 12 } />,
        navLink: "/memberships/transactions",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      }
    ]
  }
]
