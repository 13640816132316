import React from "react"
import { Circle, User, Calendar, MessageCircle, Info } from "react-feather"

export const Navigation = [
  {
    id: "general",
    title: "General",
    icon: <Info color={"#fff"} size={20} />,
    children: [
      {
        id: "emailactivities",
        title: "Email Activities",
        //     icon: <Circle size={ 12 } />,
        navLink: "/general/email-activities",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "emailtracking",
        title: "Email Tracking",
        //     icon: <Circle size={ 12 } />,
        navLink: "/general/email-tracking",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "supplierverifications",
        title: "Supplier Verifications",
        //     icon: <Cir1cle size={ 12 } />,
        navLink: "/general/supplier-verifications",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      }
    ]
  }
]
