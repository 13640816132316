import { lazy } from "react"

export const Routes = [
  {
    path: "/categories/all-categories",
    component: lazy(() => import("../views/all-categories")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     }
]
