import React from "react"
import { Circle, User, Calendar, MessageCircle, List } from "react-feather"

export const Navigation = [
  {
    id: "products",
    title: "Products",
    icon: <List color={"#fff"} size={20} />,
    children: [
      {
        id: "allitems",
        title: "All Items",
        //     icon: <Circle size={ 12 } />,
        navLink: "/products/all-items",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "importitems",
        title: "Import Items",
        //     icon: <Circle size={ 12 } />,
        navLink: "/products/import-items",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      },
      {
        id: "itemsfiles",
        title: "Items Files",
        //     icon: <Circle size={ 12 } />,
        navLink: "/products/items-files",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      }
    ]
  }
]
