import React from "react"
import { Circle, User, Calendar, MessageCircle, Clock } from "react-feather"

export const Navigation = [
  {
    id: "reports",
    title: "Reports",
    icon: <Clock color={"#fff"} size={20} />,
    children: [
      {
        id: "report",
        title: "reports",
        //     icon: <Circle size={ 12 } />,
        navLink: "/reports",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      }
    ]
  }
]
