// **  Initial State
const initialState = {
    productIds: {}
}

export const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "productIds":
            const products = action?.data
            const ids = products.map((product) => {
                return product.id
        })
            return {...state, productIds: ids}
        default:
            return state
    }
}
