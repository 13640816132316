import React from "react"
import { Circle, User, Calendar, MessageCircle, Users } from "react-feather"

export const Navigation = [
  {
    id: "shippers",
    title: "Shippers",
    icon: <Users color={"#fff"} size={20} />,
    children: [
      {
        id: "allshippers",
        title: "All Shippers",
        //     icon: <Circle size={ 12 } />,
        navLink: "/shippers/all-shippers",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
         },
         {
          id: "shippingcompanies",
          title: "Shipping Companies",
          //     icon: <Circle size={ 12 } />,
          navLink: "/shippers/shipping-companies",
          action: "call",
          resource: "USR_VIEW_ROLE_LIST"
        }
    ]
  }
]
