import React from "react"
import { Circle, User, Calendar, MessageCircle, Info } from "react-feather"

export const Navigation = [
  {
    id: "guidelines",
    title: "GuideLines",
    icon: <Info color={"#fff"} size={20} />,
    children: [
         {
              id: "guidelineslist",
              title: "Guidelines List",
              //     icon: <Circle size={ 12 } />,
              navLink: "/guidelines/guidelines-list",
              action: "call",
              resource: "USR_VIEW_ROLE_LIST"
         }
    ]
  }
]
