import('./fireBase-init')
export { Routes } from './routes'
export { Navigation } from './navigation'
export { Reducer } from './redux/reducers'
export { Lang } from './assets/data/locales'
export { default as Sagas } from './redux/sagas'
export { Axios } from './utility/API'
// // export { _logout, _autoLogin, _searchRole, _getRole, _changeCurrentRole } from './redux/actions'
export { default as CanCall } from './components/CanCall'
export { isUserLoggedIn, storedUser } from './utility/Utils'
