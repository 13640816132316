import { lazy } from "react"

export const Routes = [
  {
    path: "/shippers/shipping-companies",
    component: lazy(() => import("../views/shipping-companies")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  },
  {
    path: "/shippers/all-shippers",
    component: lazy(() => import("../views/all-shippers")),
    meta: {
      action: "call",
      resource: "NoPermissionCode"
    }
  }
]
