import { lazy } from "react"

export const Routes = [
  {
    path: "/buyers/all-buyers",
    component: lazy(() => import("../views/all-buyers")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     },
     {
          path: "/buyers/buyers-messages",
          component: lazy(() => import("../views/buyers-messages")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
        }
]
