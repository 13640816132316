import React from "react"
import { Circle, User, Calendar, MessageCircle } from "react-feather"

export const Navigation = [
  {
    id: "static-pages",
    title: "Static Pages",
    icon: <MessageCircle color={"#fff"} size={20} />,
    children: [
      {
        id: "pageslist",
        title: "Pages List",
        //     icon: <Circle size={ 12 } />,
        navLink: "/static-pages/pages-list",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
         },
         {
          id: "sitepavillions",
          title: "Site Pavillions",
          //     icon: <Circle size={ 12 } />,
          navLink: "/static-pages/site-pavillions",
          action: "call",
          resource: "USR_VIEW_ROLE_LIST"
        }
    ]
  }
]
