import { lazy } from "react"

export const Routes = [
  {
    path: "/stores/waiting-stores",
    component: lazy(() => import("../views/waiting-stores")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     },
     {
          path: "/stores/abandoned-stores",
          component: lazy(() => import("../views/abandoned-stores")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/stores/active-stores",
          component: lazy(() => import("../views/active-stores")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/stores/all-stores",
          component: lazy(() => import("../views/all-stores")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/stores/bulk-stores",
          component: lazy(() => import("../views/bulk-stores")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
           }
     
     
]
