// **  Initial State
const initialState = {
    categoryIds: {}
}

export const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "categoryIds":
            const categories = action?.data
            const ids = categories.map((category) => {
                return category.id
            })
            return {...state, categoryIds: ids}
        default:
            return state
    }
}