import { lazy } from "react"

export const Routes = [
  {
    path: "/request-for-qoutation/abandoned-buying-quotation",
    component: lazy(() => import("../views/abandoned-buying-quotation")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     },
     {
          path: "/request-for-qoutation/buying-request-invoices",
          component: lazy(() => import("../views/buying-requests-invoices")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/request-for-qoutation/closed-buying-quotation",
          component: lazy(() => import("../views/closed-buying-requests")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/request-for-qoutation/global-buying-quotation",
          component: lazy(() => import("../views/global-buying-requests")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
     },
     {
          path: "/request-for-qoutation/pending-buying-quotation",
          component: lazy(() => import("../views/pending-buying-requests")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
           },
           {
               path: "/request-for-qoutation/product-buying-quotation",
               component: lazy(() => import("../views/product-buying-requests")),
               meta: {
                action: "call",
                resource: "NoPermissionCode"
              }
                }
]
