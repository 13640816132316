import React from "react"
import { Circle, User, Calendar, MessageCircle, Users } from "react-feather"

export const Navigation = [
  {
    id: "suppliers",
    title: "Suppliers",
    icon: <Users color={"#fff"} size={20} />,
    children: [
      {
        id: "all-suppliers",
        title: "All Suppliers",
        //     icon: <Circle size={ 12 } />,
        navLink: "/suppliers/all-suppliers",
        action: "call",
        resource: "USR_VIEW_ROLE_LIST"
      }
    ]
  }
]
