import { lazy } from "react"

export const Routes = [
  {
    path: "/general/email-activities",
    component: lazy(() => import("../views/email-activities")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     },
     {
          path: "/general/email-tracking",
          component: lazy(() => import("../views/email-tracking")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
           },
     {
          path: "/general/supplier-verifications",
          component: lazy(() => import("../views/suppliers-verifications")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
        }
]
