import { lazy } from "react"

export const Routes = [
  {
    path: "/static-pages/pages-list",
    component: lazy(() => import("../views/pages-list")),
    meta: {
     action: "call",
     resource: "NoPermissionCode"
   }
     },
     {
          path: "/static-pages/site-pavillions",
          component: lazy(() => import("../views/site-pavillions")),
          meta: {
           action: "call",
           resource: "NoPermissionCode"
         }
        }
]
